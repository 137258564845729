



















import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class TheSnackbar extends Vue {

    static defaultTimeout = 5000;

    show = false;
    timeout: number = TheSnackbar.defaultTimeout;
    message = '';
    color = 'info';

    @Watch('$store.state.snackbar.version')
    setMessage(): void {
        this.show = false;
        setTimeout(() => {
            this.show = true;
            this.message = this.$store.state.snackbar.message;
        }, 100);
    }

    @Watch('$store.state.snackbar.level')
    setColor(): void {
        this.color = this.$store.state.snackbar.level;
        if (this.color === 'error') {
            this.timeout = 0;
        } else {
            this.timeout = TheSnackbar.defaultTimeout;
        }
    }
}
