














































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TheSnackbar from '@/components/TheSnackbar.vue';
import { StringLatinModelService } from "./api/StringLatinModelService";
import FocusUtils from './api/FocusUtils';

@Component({
    components: { TheSnackbar }
})
export default class App extends Vue {

    drawer = false;

    newsDialog = false;
    newsDialogDontAskAgain = false;

    automaticFocus: boolean = this.$store.state.config.automaticFocus;
    compactView: boolean = this.$store.state.config.compactView;

    newsSeen: boolean = this.$store.state.config.newsSeen;

    dataversion = "";
    dataname = "";

    helpMapping: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any 

    private language = "de";

    displaySerif = this.$store.state.config.displaySerif;

    @Prop({type: StringLatinModelService, required: true})
    private readonly model!: StringLatinModelService;

    mounted(): void {
      console.debug("App.vue: mounted()");

      window.addEventListener('keyup', (event: KeyboardEvent) => {
        // F1-Key
        if (event.keyCode === 112) {
          event.preventDefault();
          this.helpRequested();
          return;
        }

        // F8-Key
        if ((event.keyCode === 119) && (this.featureHistory === true)) {
          event.preventDefault();
          this.__toggleHistory();
          return;
        }

      });

      this.model.getModelProperties().then((result) => {
        this.dataversion = result.dataversion;
        this.dataname = result.name;
      });

      this.__prepareHelpMapping();

      this.__updateOrResetStorageModel();

      if (this.newsSeen === false) {
        this.newsDialog = true;
        this.$nextTick(() => {
          setTimeout(() => {
            const btn = FocusUtils.getHTMElem(this,"newsDialogYesButton");
            if (btn) {
              FocusUtils.focus(btn);
            }
          },FocusUtils.RENDER_DELAY);
        });
      }
    }

    //
    // Computed
    //
    get featureHistory(): boolean {
      return this.$store.state.feature.history;
    }

    get featureSerif(): boolean {
      return this.$store.state.feature.serif;
    }

    get existingStoreModelVersion(): number {
      return this.$store.state.config.storageModelVersion; 
    }

    //
    // Functions
    //
    displaySerifChanged(): void {
      this.$store.dispatch('config/updateDisplaySerif', this.displaySerif);
    }

    compactViewChanged(): void {
      this.$store.dispatch('config/updateCompactView', this.compactView);
    }

    automaticFocusChanged(): void {
      this.$store.dispatch('config/updateAutomaticFocus', this.automaticFocus);
    }

    //
    // Online help requested
    //
    helpRequested(): void { 
      console.debug("helpRqeuested().");
      const activeElem = this.findActiveElement();
      let helpUrl = this.__mapActiveElement(activeElem);
      helpUrl = helpUrl.replaceAll(new RegExp("%%lang%%","g"),this.language);

      this._openWindow(helpUrl, "help");
    }

    newsRequested(): void {
      console.debug("newsRequested().");
      this.$store.dispatch('config/updateNewsSeen', this.newsDialogDontAskAgain);
      this.newsDialog = false;

      let helpUrl = this.helpMapping["special/prefix"] + this.helpMapping["App/news"];
      // Add locale (ie. this.language) to URL: e.g. lang=de
      helpUrl = helpUrl.replaceAll(new RegExp("%%lang%%","g"),this.language);

      this._openWindow(helpUrl, "help");
    }

    newsDenied(): void {
      console.debug("newsDenied().");
      this.$store.dispatch('config/updateNewsSeen', this.newsDialogDontAskAgain);
      this.newsDialog = false;
    }

    private __updateOrResetStorageModel(): void {
      const currentStorageModelVersion = 1; // Sync with config.ts 
      const existingStorageModelVersion = this.existingStoreModelVersion;

      if ((existingStorageModelVersion) && (existingStorageModelVersion === currentStorageModelVersion)) {
        console.debug("__updateOrResetStorageModel(): Detected from local storage, that browser has been here before and model version matches -- nothing to do.");

      } else {
        console.debug("__updateOrResetStorageModel(): Detected from local storage, that browsers hasn't been here before or constraints an older model version -- reseting it.");
        this.$store.dispatch('config/resetStorageModel');

      }
    }

    private __toggleHistory(): void {
      console.debug("Current route: '" + this.$route.name + "'.");
      if (this.$route.name === 'home') {
        this.$router.push({ name: 'history' });

      } else {
        this.$router.push({ name: 'home' });
        
      }
    }

    private __gotoHome(): void {
      this.$router.push({ name: 'home' });
    }

    private __aboutRequested(): void {
      // Opening using javascript necessary, because for security reasons 
      // refuses to close windows, that have not been opened using javascript.
      const aboutUrl = "help/%%lang%%/about.html".replace(new RegExp("%%lang%%","g"),this.language);
      this._openWindow(aboutUrl,"about");
    }

    private __resetnotifications(): void {
      this.$store.dispatch('config/updateNewsSeen', false);
    }

    private _openWindow(url: string, target = ""): void {
      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

      console.debug("_openWindow: \"" + url + "\".");
      const newWindow = window.open(url, target, "menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=yes, resizable=yes, dependent, innerWidth=" + w + ", innerHeight=" + h);
      if (newWindow) {
        FocusUtils.focus(newWindow);
      }
    }

    findActiveElement(): { component: string, id: string } {
      const activeElemId = (document.activeElement) ? document.activeElement.id : "0";
      const component = "App";
      console.debug("Active element: component=\"" + component + "\", id=\"" + activeElemId + "\".");
      return { 
        component: component,
        id : activeElemId
      };
    }

    // 
    // Map active element to section identifier
    //
    private __mapActiveElement(elem: { component: string, id: string }): string {
      if (elem === null) {
        return this.helpMapping["special/fallback"];

      }

      const key = elem.component + "/" + elem.id;
      console.debug("Help requested for context key=\"" + key + "\".");

      let result = null;
      const keyBoardRe = new RegExp("App/id[0-9a-f]+");
      if (keyBoardRe.exec(key)) {
        // Id, that matches certain syntax are keyboard keys.
        result = this.helpMapping["App/keyboard"];
      } else {
        // Check mapping
        result = this.helpMapping[key];
      }

      if (result == null) { // Compare for null in all variants
        // Map to root page
        console.debug("No help mapping found for key \"" + key + "\" -- mapping to root page.");
        result = this.helpMapping["special/fallback"];
      }

      // Add prefix to complete url
      result = this.helpMapping["special/prefix"] + result;

      // Add locale (ie. this.language) to URL: e.g. lang=de
      result = result.replace(new RegExp("%%lang%%","g"),this.language);

      console.debug("Mapping help from " + key + " to \"" + result + "\".");
      return result;
    }


    //
    // Prepare help mapping
    //
    private __prepareHelpMapping() {
      console.debug("Prepare help mapping.");

      //
      // Load JSON file containing the mappings for online help
      //
      fetch("help-mapping.json", {
        credentials: "same-origin", 
      }).then((response) => {
        if (! response.ok) {
          throw new Error('Cannot load help mapping ' + response.statusText);
        }

        return response.text();

      }).then((text: string) => {
        //
        // JavaScript style comments line comments processing
        //
        const commentRe = new RegExp("^\\s*//");
        const stripped = text.split("\n").filter((line) => {
          return commentRe.exec(line) === null;
        }).join("\n");
        return JSON.parse(stripped);

      }).then((json) => {
        this.helpMapping = json;

      });
    }

}
