





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { StringLatinModelService } from "../api/StringLatinModelService";

@Component
export default class History extends Vue {

    @Prop({type: StringLatinModelService, required: true})
    private readonly model!: StringLatinModelService;


    mounted(): void {
      console.debug("History.vue: mounted()");
      
    }

    //
    // Computed
    //
    get compactView(): boolean {
      return this.$store.state.config.compactView;
    }
}
